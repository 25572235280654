<template>
  <div class="payment-method-editor">
    <v-toolbar flat dense class="payment-methods-toolbar">
      <v-spacer />
      <div v-if="runningAction" class="px-4">
        <v-progress-circular
          indeterminate
          :size="24"
          :width="2"
          color="grey darken-3"
        />
      </div>
      <v-btn
        icon
        data-test-id="paymentMethodsReadOnlyBtn"
        :color="viewMode === 'view' ? 'primary' : 'black'"
        :disabled="runningAction"
        @click="viewMode = 'view'"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-divider inset vertical class="mx-2" />
      <v-btn
        icon
        :color="viewMode === 'edit' ? 'primary' : 'black'"
        :disabled="runningAction"
        data-test-id="paymentMethodsFormEditorBtn"
        @click="viewMode = 'edit'"
      >
        <v-icon>mdi-form-textbox</v-icon>
      </v-btn>
      <v-divider inset vertical class="mx-2" />
      <v-btn
        icon
        data-test-id="paymentMethodsJsonEditorBtn"
        :color="viewMode === 'json' ? 'primary' : 'black'"
        :disabled="runningAction"
        @click="viewMode = 'json'"
      >
        <v-icon>mdi-code-json</v-icon>
      </v-btn>
      <v-divider inset vertical class="mx-2" />
      <v-switch
        v-model="groupByProviders"
        data-test-id="groupByProvidersSwitch"
        label="Group by Providers"
        color="primary"
        hide-details
        class="mx-3"
        :disabled="runningAction || viewMode !== 'edit'"
      />

      <v-btn
        class="ml-2"
        data-test-id="savePaymentMethodsBtn"
        :outlined="!isDirty"
        :color="hasViolation ? 'error' : 'green'"
        :disabled="runningAction"
        @click="savePaymentMethods"
      >
        <ViolationAlert
          v-if="hasViolation"
          :violation="violation"
          :color="isDirty ? 'white' : 'error'"
          alignment="left"
        />
        Save
      </v-btn>
    </v-toolbar>
    <DataEditor
      v-if="uimodel"
      :uimodel="uimodel"
      :loading="runningAction"
      :show-label="false"
      :ext-view-mode="viewMode"
      :style="{
        height: editorHeight + 'px',
      }"
      :key="updateEditor"
      @restore="restore"
      @input="setData"
    />
  </div>
</template>

<script>
import DataEditor from "components/configuration/data/editors/DataEditor";
import ViolationAlert from "components/common/display-helpers/ViolationAlert";
import paymentMixin from "mixins/payment-mixin";
import configServiceMixin from "mixins/config-service-mixin";
export default {
  mixins: [paymentMixin, configServiceMixin],
  components: {
    DataEditor,
    ViolationAlert,
  },

  inject: ["getEditorHeight"],

  provide() {
    return {
      getSource: this.getSource,
      getViolations: this.getViolations,
      getEditorHeight: () => {
        return this.editorHeight;
      },
      setMethodValidity: this.setMethodValidity,
    };
  },

  data() {
    return {
      schema: null,
      data: null,
      methodsMergedData: null,
      methodsSavedData: null,
      runningAction: false,
      uimodel: null,
      violation: null,
      isDirty: false,
      paymentProviders: [],
      sources: [],
      groupByProviders: false,
      viewMode: "edit",
      methodsValidity: {},
      updateEditor: true,
    };
  },

  watch: {
    data: {
      handler: function (data) {
        this.isDirty = !this.$isEqual(data, this.methodsSavedData);
      },
      deep: true,
    },

    isDirty(isDirty) {
      this.$set(this.$route.params, "unsavedChanges", isDirty);
    },

    groupByProviders(groupByProviders) {
      let dataUI = this.uimodel.schema.dataUI;
      let editorParameters = dataUI?.editorParameters ?? {};
      this.$set(editorParameters, "groupByProviders", groupByProviders);
      this.$set(dataUI, "editorParameters", editorParameters);
    },
  },

  mounted() {
    Promise.allSettled([
      this.loadPaymentMethodData(),
      this.loadPaymentProviders(),
    ]);
  },

  methods: {
    async loadPaymentMethodData() {
      try {
        this.runningAction = true;

        const mergedSchemaRes =
          await this.$store.$coreApi.coreConfigurationApi.getMergedSchema(
            this.selectedDomain,
            this.keySpace,
            this.keyPattern
          );
        this.schema = mergedSchemaRes?.schema;

        const mergedDataRes =
          await this.$store.$coreApi.coreConfigurationApi.getMergedData(
            this.selectedDomain,
            this.keySpace,
            this.keyPattern,
            null,
            {
              query: "withInheritanceSources=true",
            }
          );

        this.methodsMergedData = mergedDataRes?.data;
        this.sources = mergedDataRes?.sources ?? [];

        //retrieve data of this domain
        const fullData =
          await this.$store.$coreApi.coreConfigurationApi.getData(
            this.selectedDomain,
            this.keySpace,
            this.keyPattern,
            {
              includeParents: false,
            }
          );

        const data = fullData?.[0]?.data;
        //deep clone of data for usage and restore
        this.data = this.$cloneObject(data);
        this.methodsSavedData = this.$cloneObject(data);

        this.buildUiModel();
      } finally {
        this.runningAction = false;
      }
    },

    buildUiModel() {
      let schema = this.schema;
      if (schema) {
        if (!schema?.dataUI) {
          this.$set(schema, "dataUI", {});
        }
        this.$set(schema.dataUI, "editor", "PaymentMethodOverview");
      }

      const uimodel = {
        schema: this.schema,
        data: this.$cloneObject(this.data),
        savedData: this.$cloneObject(this.methodsSavedData),
        mergedData: this.$cloneObject(this.methodsMergedData),
        path: "$",
        inheritable: true,
        parentData: this.data,
        editable: true,
        domain: this.selectedDomain,
        property: null,
        dataLevel: 0,
        isRoot: true,
        keySpace: this.keySpace,
        key: this.keyPattern,
      };
      this.uimodel = uimodel;
    },

    async savePaymentMethods() {
      try {
        this.violation = null;
        if (!this.validate()) {
          const violations = this.getInvalidMethodsViolations();
          this.violation = {
            message: "At least one payment method configuration is invalid",
            violations,
          };
          return;
        }
        this.runningAction = true;
        this.expanded = [];
        const res = await this.upsertData(
          this.keySpace,
          this.keyPattern,
          this.data,
          "Payment methods saved"
        );
        if (res?.status !== 200) {
          if (res?.violation) {
            this.violation = res.violation;
          }
          return;
        }

        await this.loadPaymentMethodData();
        this.methodsValidity = {};
        this.updateEditor = !this.updateEditor;
      } finally {
        //use $nextTick here, so that the validation
        //in PaymentMethodOverview is correctly triggered
        await this.$nextTick();
        this.runningAction = false;
      }
    },

    restore() {
      this.data = this.$cloneObject(this.methodsSavedData);
      this.$nextTick(() => this.buildUiModel());
    },

    setData(data) {
      this.data = this.$cloneObject(data);
    },

    setMethodValidity(methodId, isValid) {
      this.$set(this.methodsValidity, methodId, isValid);
    },

    validate() {
      const methods = Object.keys(this.methodsValidity);
      const hasInvalid = methods.some((id) => {
        const isValid = this.methodsValidity[id];
        return isValid === false;
      });
      return !hasInvalid;
    },

    getInvalidMethodsViolations() {
      const methods = Object.keys(this.methodsValidity);
      return methods.reduce((array, id) => {
        const isValid = this.methodsValidity[id];
        if (isValid === false) {
          const path = "$." + id;
          array.push({
            message: "At least one input has invalid values",
            property: path,
          });
        }
        return array;
      }, []);
    },
  },

  computed: {
    keyPattern() {
      return "core.payment.methods";
    },

    hasViolation() {
      return !!this.violation;
    },

    editorHeight() {
      return (
        this.getEditorHeight() -
        this.$vToolbarHeightDense -
        this.$vToolbarMarginBottom
      );
    },
  },
};
</script>

<style scoped>
.payment-method-editor {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 12px;
}
</style>